import Vue from 'vue';
import vuetify from './vuetify';
import App from './layouts/App.vue'
import router from './routes/router'
import moment from 'moment'
import knowledgebaseClient from './helpers/knowledgebaseClient'

Vue.filter('moment', function (value, format) {
    return moment(value).format(format);
});

// const guestAllowedRoutes = [
//     'home', 'posts-show'
// ];

new Vue({
    el: "#app",
    router,
    vuetify,
    render: h => h(App),
    beforeMount() {
        knowledgebaseClient.interceptors.response.use((response) => {
            return response
        }, (error) => {
            console.log(error)
            // if (error.response.status === 401 && !guestAllowedRoutes.includes(this.$route.name)) {
            //     window.location.href = '/login'
            // }
            
            return Promise.reject(error)
        })
        knowledgebaseClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`
    }
})
