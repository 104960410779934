import knowledgebaseClient from "../helpers/knowledgebaseClient";
export default {
  async getPosts() {
    let response = await knowledgebaseClient({
      data: {
        query: `{
                    posts {
                        id,
                        title,
                        description,
                        file {
                            name,
                            url
                        }
                    }
                }`,
      },
    });
    if (response.errors) {
      // find the errors and throw the errors
    }
    return response.data.data.posts;
  },
  async getPost(id) {
    let response = await knowledgebaseClient({
      data: {
        query: `{
                    post(id: "${id}") {
                        id,
                        title,
                        description,
                        file {
                            id,
                            name,
                            url
                        }
                    }
                }`,
      },
    });
    if (response.errors) {
      // find the errors and throw the errors
    }
    let post = response.data.data.post;
    post.fileId = post.file?.id;
    return post;
  },
  async createPost(post) {
    let response = await knowledgebaseClient({
      data: {
        query: `mutation {
            createPost(post: {
                title: "${post.title}",
                fileId: "${post.fileId}",
                description: "${post.description}"
            }) {
                id,
                title,
                description,
                file {
                    name,
                    url
                }
            }
        }`,
      },
    });
    if (response.errors) {
      // find the errors and throw the errors
    }
    return response.data.data.createPost;
  },
  async updatePost(post) {
    let response = await knowledgebaseClient({
      data: {
        query: `mutation {
          updatePost(post: {
              id: "${post.id}",
              title: "${post.title}",
              description: "${post.description}",
              fileId: "${post.fileId}"
          }) {
              id,
              title,
              description,
              file {
                name,
                url
              }
          }
      }`,
      },
    });
    if (response.errors) {
      // find the errors and throw the errors
    }
    return response.data.data.updatePost;
  },
  async getPublicPosts() {
    let response = await knowledgebaseClient({
      data: {
        query: `{
                    publicPosts {
                        id,
                        title,
                        description,
                        file {
                            name,
                            url
                        }
                        author {
                          name,
                          profilePicUrl
                        }
                    }
                }`,
      },
    });

    return response.data.data.publicPosts;
  },
};
