import PostIndex from '../pages/post/Index'
import PostCreate from '../pages/post/Create'
import PostEdit from '../pages/post/Edit'
import PostShow from '../pages/post/Show'

export default [
    {
        path: "/posts",
        name: "posts-index",
        component: PostIndex,
    },
    {
        path: "/posts/create",
        name: "posts-create",
        component: PostCreate,
    },
    {
        path: "/posts/:postId/edit",
        name: "posts-edit",
        component: PostEdit,
    },
    {
        path: "/posts/:postId",
        name: "posts-show",
        component: PostShow,
    },
]