<template>
  <v-container>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" :to="{name: 'posts-create'}">
            Create
          </v-btn>
        </v-col>
          <v-col
            cols="12"
          >
            <v-sheet
              rounded="lg"
            >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Title
                      </th>
                      <th class="text-left">
                        Created At
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="post in posts"
                      :key="post.name"
                    >
                      <td>{{ post.title }}</td>
                      <td>{{ post.created_at | moment('DD MMM YYYY') }}</td>
                      <td>
                        <v-btn color="primary" :to="{name: 'posts-edit', params: {postId: post.id}}">
                        <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-sheet>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import PostRepo from '../../repo/PostRepo';
export default {
  data() {
    return {
      posts: []
    }
  },
  methods: {
    async getPosts() {
      this.posts = await PostRepo.getPosts();
    }
  },
  async beforeMount() {
    await Promise.all([this.getPosts()]);
  }
}
</script>

<style>

</style>