<template>
  <v-container>
    <v-col cols="12">
      <v-sheet rounded="lg" class="pa-5">
        <post-form v-model="post" :rules="rules" @submit="submit"></post-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import PostForm from "./partials/Form.vue";
import PostRepo from '../../repo/PostRepo';
import FileRepo from '../../repo/FileRepo';

export default {
  data: () => ({
    post: {
      title: null,
      description: null,
      file: null,
    },
    rules: {
      title: [(v) => !!v || "Title is required"],
      description: [(v) => !!v || "Description is required"],
    },
  }),

  methods: {
    async submit() {
      if (this.post.rawFile) {
        const file = await FileRepo.uploadFile(this.post.rawFile);
        this.post.fileId = file.id;
      }
      const post = await PostRepo.createPost(this.post);
      this.$router.push({
        name: "posts-edit",
        params: { postId: post.id },
      });
    },
  },
  components: {
    PostForm,
  },
};
</script>

<style></style>
