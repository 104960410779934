<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="2">
        <!-- <v-sheet rounded="lg" min-height="268">
        </v-sheet> -->
      </v-col>

      <v-col cols="12" sm="8">
        <v-card
          class="mx-auto mb-5"
          v-for="post in publicPosts"
          :key="post.id"
          :to="{ name: 'posts-show', params: { postId: post.id } }"
        >
          <file-previewer
            :file="post.file"
            height="200px"
            :toolbar="false"
          ></file-previewer>

          <v-card-title> {{ post.title }} </v-card-title>

          <v-card-subtitle> {{ post.description }} </v-card-subtitle>

          <v-card-actions>
            <v-list-item class="grow" v-if="post.author">
              <v-list-item-avatar>
                <img :src="post.author.profilePicUrl" alt="" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  post.author && post.author.name
                }}</v-list-item-title>
              </v-list-item-content>

              <!-- <v-row align="center" justify="end">
                <v-icon class="mr-1"> mdi-heart </v-icon>
                <span class="subheading mr-2">256</span>
                <span class="mr-1">·</span>
                <v-icon class="mr-1"> mdi-share-variant </v-icon>
                <span class="subheading">45</span>
              </v-row> -->
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="2">
        <!-- <v-sheet rounded="lg" min-height="268">
        </v-sheet> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilePreviewer from "../components/FilePreviewer.vue";
import knowledgebaseClient from "../helpers/knowledgebaseClient";
import PostRepo from "../repo/PostRepo";
export default {
  data() {
    return {
      publicPosts: [],
    };
  },
  methods: {
    async getPublicPosts() {
      this.publicPosts = await PostRepo.getPublicPosts();
      //  = response.data.data.map(post => {
      //   post.files = [];
      //   return post;
      // })
      // this.publicPosts.map(post => this.getPostFiles(post))
    },
    async getPostFiles(post) {
      const response = await knowledgebaseClient.get(`/api/post-files`, {
        params: {
          post_id: post.id,
        },
      });
      post.files = response.data.data || [];
    },
  },
  components: {
    FilePreviewer,
  },
  async beforeMount() {
    await Promise.all([this.getPublicPosts()]);
  },
};
</script>

<style></style>
