<template>
  <v-container>
    <v-col cols="12">
      <v-sheet rounded="lg" class="pa-5">
        <post-form v-model="post" :rules="rules" @submit="submit" class="mb-5"></post-form>
        <file-previewer :file="post.file" style="height:100vh"></file-previewer>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import PostForm from "./partials/Form.vue";
import FilePreviewer from '../../components/FilePreviewer.vue'
import PostRepo from '../../repo/PostRepo';
import FileRepo from '../../repo/FileRepo';

export default {
  data: () => ({
    post: {
      title: null,
      description: null,
      file: null,
    },
    rules: {
      title: [(v) => !!v || "Title is required"],
      description: [(v) => !!v || "Description is required"],
    },
  }),
  methods: {
    async getPost() {
      this.post = await PostRepo.getPost(this.$route.params.postId);
    },
    async submit() {
      if (this.post.rawFile) {
        const file = await FileRepo.uploadFile(this.post.rawFile);
        this.post.fileId = file.id;
      }
      const post = await PostRepo.updatePost(this.post);
      this.$router.push({
        name: "posts-edit",
        params: { postId: post.id },
      });
    },
  },
  async beforeMount() {
    await Promise.all([this.getPost()]);
  },
  components: {
    PostForm,
    FilePreviewer
  },
};
</script>

<style></style>
