export default {
  importScript() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute("src", "https://accounts.google.com/gsi/client");
    googleScript.setAttribute("async", true);
    googleScript.setAttribute("defer", "defer");
    document.head.appendChild(googleScript);
  },
  prompt() {
    window.google.accounts.id.prompt(); // also display the One Tap dialog
  }
};
