<template>
  <v-container>
    <v-col>
      <v-sheet rounded="lg" class="pa-5">
        <file-previewer
          :file="post.file"
          :toolbar="false"
          style="height:100vh"
        ></file-previewer>
        <v-divider class="my-3"></v-divider>
        <v-list-item class="grow">
          <v-list-item-avatar color="grey darken-3">
            <v-img
              class="elevation-6"
              alt=""
              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ post.author && post.author.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ post.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ post.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import FilePreviewer from "../../components/FilePreviewer.vue";
import PostRepo from '../../repo/PostRepo';

export default {
  data: () => ({
    post: {
      title: null,
      description: null,
      file: null,
    },
    rules: {
      title: [(v) => !!v || "Title is required"],
      description: [(v) => !!v || "Description is required"],
    },
  }),
  methods: {
    async getPost() {
      // const response = await axios.get(
      //   `/api/posts/${this.$route.params.postId}`
      // );
      this.post = await PostRepo.getPost(this.$route.params.postId);
    },
  },
  async beforeMount() {
    await Promise.all([this.getPost()]);
  },
  components: {
    FilePreviewer,
  },
};
</script>

<style>
</style>