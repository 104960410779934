<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-tabs centered class="ml-n9" color="grey darken-1">
        <v-tab :to="{ name: 'home' }"> Home </v-tab>
        <v-tab :to="{ name: 'posts-index' }" v-if="authUser"> My Post </v-tab>
      </v-tabs>

      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
        v-if="authUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
            <v-avatar v-if="authUser.profilePicUrl">
              <img :src="authUser.profilePicUrl" alt="">
            </v-avatar>
            <v-avatar
              color="brown"
              size="48"
              v-else
            >
              <span class="white--text text-h5">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="brown"
              >
                <span class="white--text text-h5">{{ initials }}</span>
              </v-avatar>
              <h3>{{ authUser.name }}</h3>
              <p class="text-caption mt-1">
                {{ authUser.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="logout"
              >
                Log out
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
      <template v-else>
      <google-login v-if="!authUser" @loggedIn="getAuthUser()"></google-login>
      </template>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import GoogleLogin from '../components/oauth/GoogleLogin';
import UserRepo from '../repo/UserRepo'
export default {
  data: () => ({
    processing: true,
    authUser: null,
  }),
  computed: {
    initials() {
      return (
        this.authUser?.name
          .replace(/[^\p{L}\s]|_|\d/gu, "") // remove all non letters from the string
          .replace(/\s+/g, " ") // trailing space to single char
          .replace(/^\s+|\s+$/g, "") // remove spaces in the begining and the end
          .split(" ") // split the string to the array of names
          .filter((word, index, arr) => index === 0 || index === arr.length - 1)
          // get the first and last names only
          .map((word) => word.substr(0, 1)) // get the first letters from the names
          .join("") // convert to string
          .toUpperCase()
      );
    },
  },
  methods: {
    async getAuthUser() {
      this.authUser = await UserRepo.getAuthUser();
      if (this.authUser == null) {
        localStorage.removeItem('authToken');
      }
    },
    async logout() {
      localStorage.removeItem('authToken');
      window.location.href = '/'
    }
  },
  async beforeMount() {
    await Promise.all([this.getAuthUser()]);
  },
  components: {
    GoogleLogin
  }
};
</script>