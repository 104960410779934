import knowledgebaseClient from "../helpers/knowledgebaseClient";
import s3Client from "../helpers/s3Client";
// import { readFileSync } from "fs";
export default {
  async uploadFile(rawFile) {
    console.log(rawFile);
    // get the file name
    const file = await this.createFile({
      name: rawFile.name,
      type: rawFile.type
    });
    // get the s3 link
    const s3PresignedUrl = await this.getS3PresignedUrl(file);
    console.log("before s3 upload");
    // upload file to s3
    await this.uploadFileToS3(s3PresignedUrl, rawFile);
    console.log("after s3 upload");
    return file;
  },

  async createFile(file) {
    let response = await knowledgebaseClient({
      data: {
        query: `mutation {
                createFile(file: {
                    name: "${file.name}",
                    type: "${file.type}"
                }) {
                    id,
                    name,
                    path,
                    type
                }
            }`,
      },
    });
    if (response.errors) {
      // find the errors and throw the errors
    }
    return response.data.data.createFile;
  },

  async getS3PresignedUrl(file) {
    let response = await knowledgebaseClient({
      data: {
        query: `{
            getSignedUploadUrl (id: "${file.id}")
        }`,
      },
    });
    if (response.errors) {
      // find the errors and throw the errors
    }
    return response.data.data.getSignedUploadUrl;
  },

  async uploadFileToS3(s3PresignedUrl, file) {
    let formData = new FormData();
    formData.append("file", file);
    await s3Client.put(
      s3PresignedUrl,
      file,
      {
        headers: {
          "Content-Type": file.type,
        },
      }
      //   {
      //     headers: {
      //       "Content-Type": "application/octet-stream",
      //     },
      //   }
    );
  },
};
