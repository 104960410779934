<template>
  <div style="position:fixed;right:10px;">
    <div id="googleLoginDiv"></div>
  </div>
</template>
<script>
import Google from "../../plugins/google.js";
import knowledgebaseClient from '../../helpers/knowledgebaseClient'
export default {
  beforeMount() {
    Google.importScript();

    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id:
          process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: (response) => {
          localStorage.setItem('authToken', response.credential);
          knowledgebaseClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`
          this.$emit('loggedIn');
          console.log("Encoded JWT ID token: " + response.credential);
        },
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleLoginDiv"),
        { theme: "outline", size: "large" } // customization attributes
      );
      // Google.prompt();
    };

    // window.onSignIn = this.onSignIn;
  },
};
</script>
<style lang=""></style>
