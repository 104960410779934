import knowledgebaseClient from '../helpers/knowledgebaseClient'
export default {
    async getAuthUser() {
        let response = await knowledgebaseClient({
            data: {
                query: `{
                    authUser {
                        name,
                        email,
                        profilePicUrl
                    }
                }`
            }
        });

        return response.data.data.authUser;
    }
}