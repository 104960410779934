<template>
  <v-row>
    <v-col v-if="file && file.url">
        <iframe v-if="fileExtension == 'pdf'" :src="`https://docs.google.com/viewer?url=${encodeURIComponent(file.url)}&embedded=true`" style="width:100%; height:100%" frameborder="0"></iframe>
        <!-- <embed :src="`${file.url}${toolbarParams}`" v-if="fileExtension == 'pdf'" style="width:100%; height:100%"/> -->
        <v-img v-else-if="extensionTypes.image.includes(fileExtension)"
        :src="file.url" height="100%" width="auto">
        </v-img>
        <iframe v-else-if="extensionTypes.office.includes(fileExtension)" style="width:100%; height:100%" :src="`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.url)}`"></iframe>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    file: {
      default() {
        return {};
      },
    },
    toolbar: {
      default: true
    }
  },
  computed: {
    toolbarParams() {
      return this.toolbar ? "" :'#toolbar=0&navpanes=0&scrollbar=0';
    },
    fileExtension() {
      return this.file && this.file.name && this.file.name.split('.').pop();
    }
  },
  data() {
      return {
          extensionTypes: {
              image: [
                  'png', 'jpg', 'jpeg'
              ],
              office: [
                  'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'
              ]
          }
      }
  }
};
</script>

<style>
</style>