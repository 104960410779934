<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
    <v-text-field
      v-model="value.title"
      :counter="10"
      :rules="rules.title"
      label="Title"
      required
    ></v-text-field>

    <v-textarea
      name="input-7-1"
      label="Description"
      v-model="value.description"
      :rules="rules.description"
      required
    ></v-textarea>

    <v-file-input show-size truncate-length="15" v-model="value.rawFile"></v-file-input>

    <v-btn :disabled="!valid" color="primary" class="mr-4" type="submit">
      Save
    </v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      default() {
        return {};
      },
    },
    rules: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    async submit() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      this.$emit("submit");
    },
    async validate() {
      await this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style>
</style>